<template>
  <div>
    <form action="#" @submit.prevent novalidate v-show="!loadingForm">
      <div v-if="route.path === '/expert/create'" class="create-area">
        <div class="flex create-expert">
          <div class="col">
            <custom-select-2
              name="Пользователь"
              label="Пользователь*"
              v-model="createData.user_id"
              :settings="usersAjax"
            />
          </div>
          <div class="col" v-if="profile && profile.role === 'superadmin'">
            <custom-select-2
              name="partner_id"
              label="Центр"
              v-model="createData.partner_id"
              :options="partnersOptions"
            />
          </div>
        </div>
        <div class="flex justify-content-end clear-btn">
          <button
            type="button"
            @click="
              () => {
                createData.user_id = null;
                createData.partner_id = null;
              }
            "
            class="btn btn-accent justify-self-end"
          >
            Очистить
          </button>
        </div>
      </div>
      <div class="inputs display--flex justify-content-center">
        <div class="col">
          <preview-file-loader
            @loadPhoto="
              (data) => {
                form.image = data;
                form.currentImage = null;
              }
            "
            @delete="
              () => {
                form.currentImage = null;
                form.image = null;
              }
            "
            :image="form.currentImage ? form.currentImage : form.image"
            class="photo-area"
            label="Фото"
            name="image"
          />
          <div class="expert-name">
            <span>ФИО</span>
            <div v-if="createData.user_id && staff">
              {{
                `${staff?.last_name} ${staff?.first_name} ${staff?.patronymic}`
              }}
            </div>
            <div v-else>
              {{ expert?.title || "Не определено" }}
            </div>
          </div>
          <custom-textarea
            name="short_description"
            label="Краткое описание"
            horizontal
            v-model="form.short_description"
            :maxLength="250"
            :height="100"
          />

          <div class="format-checkboxes flex align-item-center block">
            <div class="format-title block-title">Формат*</div>
            <div class="flex checkboxes">
              <custom-checkbox
                name="format1"
                v-model="format.online"
                :checked="format.online"
                label="Онлайн"
              />
              <custom-checkbox
                name="format2"
                v-model="format.offline"
                :checked="format.offline"
                label="Офлайн"
              />
              <custom-checkbox
                name="format3"
                v-model="format.trip"
                :checked="format.trip"
                label="Командировка"
              />
            </div>
          </div>
          <custom-select-2
            name="services"
            :multiple="true"
            label="Услуги*"
            :options="servicesOptions"
            v-model="form.services"
          />
        </div>
        <div class="col">
          <city-input
            label="Город*"
            :city="form.city"
            @changeCity="(cityId) => (form.city_id = cityId)"
          />
          <div class="editor-block block">
            <div class="editor-title block-title">Образование</div>
          </div>
          <div class="editor-block block">
            <div class="editor-title block-title">Личная история</div>
          </div>
        </div>
      </div>
      <div class="btns mt-25 mb-10 display--flex justify-content-end">
        <button
          v-if="
            getRight('partnersList') && expert && expert.status !== 'approved'
          "
          type="button"
          @click="submitExpert('approved')"
          class="btn btn-accent mr-10"
        >
          Одобрить
        </button>
        <button
          type="button"
          @click="submitExpert(false)"
          class="btn btn-accent mr-10"
        >
          Сохранить
        </button>
        <button type="button" @click="router.go(-1)" class="btn btn-w">
          Назад
        </button>
      </div>
      <div class="form-preloader" v-if="transactionLoading">
        <preloader />
      </div>
    </form>
    <div class="table-preloader" v-if="loadingForm">
      <preloader />
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import { useStore } from "vuex";
import { reactive, ref } from "@vue/reactivity";
import PreviewFileLoader from "@/components/Forms/Fields/PreviewFileLoader.vue";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import standartError from "@/mixins/standartError";
import CustomRadio from "@/components/Forms/Fields/CustomRadio.vue";
import useErrors from "@/mixins/useErrors";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import CustomTextarea from "../../../components/Forms/Fields/CustomTextarea.vue";
import CityInput from "../../../components/Forms/Fields/CityInput.vue";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import MultipleImageInput from "../../../components/Forms/Fields/MultipleImageInput.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import useCheckRights from "../../../mixins/useCheckRights";
import Preloader from "../../../components/Technical/Preloader.vue";

export default {
  components: {
    CustomInput,
    PreviewFileLoader,
    CustomRadio,
    CustomSelect2,
    CustomCheckbox,
    CustomTextarea,
    CityInput,
    MultipleImageInput,
    Preloader,
  },
  name: "expert-form",
  setup() {
    const store = useStore(),
      router = useRouter(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors();

    const { getRight } = useCheckRights();

    const profile = computed(() => store.state.profile.profile);
    const staff = computed(() => store.state.users.user);

    const services = computed(() => store.state.partners.services);
    const currentPartner = computed(() => store.state.partners.currentPartner);
    const currentExpert = computed(() => store.state.partners.currentExpert);
    const centers = computed(() => store.state.partners.centers);
    const expert = computed(() => store.state.partners.expert);

    const loadingForm = computed(() => store.state.ui.loadingForm);
    const transactionLoading = computed(
      () => store.state.ui.transactionLoading
    );


    const servicesOptions = ref([]),
      partnersOptions = ref([]);
    const { usersAjax } = useAjaxSelect();
    const form = reactive({
      city: null,
      city_id: null,
      image: null,
      currentImage: null,
      short_description: null,
      description: null,
      history: null,
      education: null,
      services: null,
    });
    const format = reactive({
      online: false,
      offline: false,
      trip: false,
    });
    const createData = reactive({ user_id: null, partner_id: null });

    onBeforeMount(() => {
      store.dispatch("partners/getServices").then(() => {
        servicesOptions.value = Object.entries(services.value).map(
          ([key, value]) => ({ id: key, text: value })
        );
      });
      store.dispatch("partners/getCenters").then(() => {
        partnersOptions.value = centers.value.map((partner) => ({
          id: partner.id,
          text: partner.title,
        }));
      });
      if (route.params.id || route.path !== "/expert/create") {
        store.commit("ui/setLoadingForm", true);
        store
          .dispatch(
            "partners/getExpert",
            route.params.id ? route.params.id : currentExpert.value.id
          )
          .then(() => {
            const {
              city,
              image,
              short_description,
              education,
              description,
              services,
              job_formats,
            } = expert.value;
            form.city = city ? city.name : null;
            form.city_id = city ? city.id : null;
            form.currentImage = image;
            form.services = services;
            job_formats.forEach((el) => {
              format[el] = true;
            });
            form.short_description = short_description;
          })
          .finally(() => store.commit("ui/setLoadingForm", false));
      }
    });

    const submitExpert = (approved) => {
      store.commit("ui/setTransactionLoading", true);
      if (route.path === "/expert/create") {
        store
          .dispatch("partners/createExpert", {
            ...form,
            user_id: Number(createData.user_id),
            partner_id:
              currentPartner.value &&
              currentPartner.value.id &&
              !createData.partner_id
                ? currentPartner.value.id
                : createData.partner_id,
            job_formats: Object.entries(format)
              .filter(([key, value]) => value)
              .map(([key, value]) => {
                return key;
              }),
          })
          .then(() => {
            router.push("/experts");
          })
          .catch(standartError(setErrors, clearCustomErrors))
          .finally(() => store.commit("ui/setTransactionLoading", false));
        return;
      }
      store
        .dispatch("partners/updateExpert", {
          id: route.params.id ? route.params.id : currentExpert.value.id,
          expert: {
            ...form,
            status: approved || expert.value.status,
            job_formats: Object.entries(format)
              .filter(([key, value]) => value)
              .map(([key, value]) => {
                return key;
              }),
          },
        })
        .catch(standartError(setErrors, clearCustomErrors))
        .finally(() => store.commit("ui/setTransactionLoading", false));
    };

    watch(
      () => createData.user_id,
      () => {
        if (!createData.user_id) {
          form.city = null;
          form.city_id = null;
          form.image = null;
          form.currentImage = null;
          return;
        }
        store.dispatch("users/getUser", createData.user_id).then(() => {
          const { city } = staff.value;
          form.city = city ? city.name : null;
          form.city_id = city ? city.id : null;
        });
      }
    );

    return {
      form,
      submitExpert,
      router,
      format,
      route,
      createData,
      servicesOptions,
      partnersOptions,
      usersAjax,
      profile,
      staff,
      expert,
      getRight,
      loadingForm,
      transactionLoading,
    };
  },
};
</script>

<style lang="scss">
.editor-block {
  .ql-editor {
    font-size: 15px;
    height: 200px;
  }
}
</style>
<style lang="scss" scoped>
@media (min-width: 769px) {
  .form-group {
    margin: 25px -5px;
  }
}

.create-area {
  border-bottom: 1px solid var(--cream-color);
  padding-bottom: 15px;
}

.create-expert .form-group {
  flex: 1;
}

.expert-name {
  font-weight: 400;
  display: flex;
  padding-left: 10px;
  span {
    flex-basis: 25%;
  }
  div {
    flex-basis: 75%;
  }
}

.clear-btn {
  padding-right: 20px;
}

.format-checkboxes {
  padding-left: 10px;
  margin-top: 15px;
  .format-title {
    flex-basis: 25%;
  }

  .checkboxes {
    gap: 28px;
  }

  @media (max-width: 973px) {
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
    .checkboxes {
      padding-left: 7px;
    }
  }

  @media (max-width: 816px) {
    .checkboxes {
      flex-direction: column;
      gap: 4px;
      margin-top: 10px;

      .form-group {
        margin: 5px -5px;
      }
    }
  }
}

.block {
  padding-left: 10px;
  .block-title {
    font-size: 15px;
    font-weight: 400;
  }
  @media (max-width: 973px) {
    padding-left: 0;
  }
}

.editor-block {
  margin-top: 15px;

  .editor-title {
    margin-bottom: 15px;
  }
}

.col {
  flex: 0 1 50%;
  padding: 0 10px;
}

@media (max-width: 1500px) {
  .inputs,
  .create-expert {
    flex-direction: column;
  }

  .clear-btn {
    padding-right: 10px;
    margin-top: 15px;
  }
}

@media (max-width: 425px) {
  .photo-area {
    margin: 0 !important;
  }
}
</style>