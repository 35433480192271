<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Эксперт</div>
      </div>
      <div class="card-body"><expert-form /></div>
    </div>
  </div>
</template>

<script>
import ExpertForm from "../../feature/partners/forms/ExpertForm.vue";
import useCheckRights from "../../mixins/useCheckRights";
export default {
  components: { ExpertForm },
  name: "expert-page",
  setup() {
    const { getRight } = useCheckRights();

    return {
      getRight,
    };
  },
};
</script>

<style>
</style>